import { fetchCurrentLocations } from "../actions/drivers";
import { dateTimeParser } from "./date";
import { i18n } from "./i18n";
import moment from "moment";
import { unitedVisitsPopup, visitPopup } from "./visits/popups";
import { groupBy } from "./utils";

const ARABIC = 'lionwheel/ckkd1kzj70jsu17n7payeu1d1'
const LOCAL = 'lionwheel/ckkd1x3d02f3817mnbj6srgtk'
const ENGLISH = 'mapbox/streets-v11'

const numberedMarker = (marker, className, number) => {
  let fontClass = ''
  if (+number < 10) {
    fontClass = 'font-size-14'
  } else if (+number < 100) {
    fontClass = 'font-size-13'
  } else  if (+number < 1000) {
    fontClass = 'font-size-12'
  }
  const numberIcon = L.divIcon({
    className: className,
    iconSize: [29, 47],
    iconAnchor: [15, 47],
    html: `<div class="map-icon-number ${fontClass}">
             ${number}
             ${marker.ambiguous_location ? ambiguousMarkerAddition() : ''}
           </div>`
  })

  const contentString = visitPopup(marker)

  // change popup format here like store visits
  return L.marker([marker.lat, marker.lon], { icon: numberIcon })
    .bindPopup(contentString, { offset: [0, -20] }).openPopup()
}

export const renderNumberedMarkers = (markers, isOptimized = true) => {
  const filtered = markers.filter(m => m.lat && m.lon)
  const res = { done: [], undone: [], failed: [] }
  var number;

  filtered.forEach((marker, i) => {
    number = isOptimized ? i + 1 : '';

    if (marker.is_done) {
      res.done = [...res.done, numberedMarker(marker, 'blue-number-icon', number)]
    } else if (marker.failed_at) {
      res.failed = [...res.failed, numberedMarker(marker, 'brown-number-icon', number)]
    } else {
      res.undone = [...res.undone, numberedMarker(marker, 'red-number-icon', number)]
    }
  })

  return res
}

export const renderNumberedUnitedMarkers = (markers, isOptimized = true) => {
  const filtered = markers.filter(m => m.lat && m.lon)
  const grouped = groupBy(filtered, (m) => `${m.lat},${m.lon}`) // Object.groupBy can cause errors in Safari
  const res = { done: [], undone: [], failed: [] }

  let currentIndex = 1

  Object.values(grouped).forEach((group) => {
    const marker = group[0]
    let number
    if (isOptimized) {
      number = group.length > 1 ? `${currentIndex}*` : `${currentIndex}`
    } else {
      number = ''
    }

    if (marker.is_done) {
      res.done = [...res.done, numberedUnitedMarker(group, 'blue-number-icon', number)]
    } else if (marker.failed_at) {
      res.failed = [...res.failed, numberedUnitedMarker(group, 'brown-number-icon', number)]
    } else {
      res.undone = [...res.undone, numberedUnitedMarker(group, 'red-number-icon', number)]
    }

    currentIndex += group.length
  })

  return res
}

const numberedUnitedMarker = (group, className, number) => {
  let fontClass = ''
  if (number.toString().length < 3) {
    fontClass = 'font-size-14'
  } else if (number.toString().length < 4) {
    fontClass = 'font-size-13'
  } else  if (number.toString().length < 5) {
    fontClass = 'font-size-12'
  }

  const firstVisit = group[0]

  const numberIcon = L.divIcon({
    className: className,
    iconSize: [29, 47],
    iconAnchor: [15, 47],
    html: `<div class="map-icon-number ${fontClass} text-nowrap">
             ${number}
             ${firstVisit.ambiguous_location ? ambiguousMarkerAddition() : ''}
           </div>`
  })

  if (number) group = group.map((el, i) => ({ ...el, order_in_route: parseInt(number) + i }))

  const contentString = unitedVisitsPopup(group)

  return L.marker([firstVisit.lat, firstVisit.lon], { icon: numberIcon })
    .bindPopup(contentString, { offset: [0, -20] }).openPopup()
}

export const renderMarkers = (locations, map) => {
  const markers = Object.entries(locations).filter(i => i[1].latitude).map((loc) => {
    const { latitude, longitude, name, partial_address: address } = loc[1]

    const numberIcon = L.divIcon({
      className: 'blue-number-icon',
      iconSize: [29, 47],
      iconAnchor: [15, 47]
    })
    return L.marker([latitude, longitude], { icon: numberIcon })
      .bindPopup(buildTip({ name, address: address })).openPopup()
      .addTo(map)
  })

  if (markers.length) {
    map.fitBounds(L.featureGroup(markers).getBounds(), { padding: [10, 10] })
  }
}

export const drawLinePolygons = (lines, map, className = '', focus = true, type = '') => (
  drawPolygons(buildLinesPolygonsData(lines), map, className, focus, type)
)

const drawPolygons = (polygonsDataArr, map, className = '', focus = true, type = '') => {
  const polygons = polygonsDataArr.map(polygonData => {
    const polygonObj = {
      color: polygonData.color,
      opacity: 0.8,
      weight: 2,
      fillColor: polygonData.color,
      fillOpacity: 0.35,
      className: className,
      type: type
    }
    return L.polygon(polygonData.coords, polygonObj).addTo(map)
  })
  if (focus && polygons.length) {
    map.fitBounds(L.featureGroup(polygons).getBounds(), { padding: [10, 10] })
  }
  return L.featureGroup(polygons)
}

export const reversePoints = points => points.map(a => a.reverse())

export const styleId = (locale) => {
  switch (locale) {
    case 'en':
      return ENGLISH
    case 'he':
      return LOCAL
    case 'ar':
      return ARABIC
    default:
      return LOCAL
  }
}

const buildLinesPolygonsData = (lines) => (
  lines.map(line => {
    return line.polygon.split(':').map(subLine => {
      const coords = subLine.split(';')
      return {
        coords: coords.map(coord => coord.split(',').map(i => parseFloat(i))),
        color: line.color
      }
    })
  }).flat()
)

export const enableDrawTool = (map, color, creationCallback = () => {}, edit = false) => {
  const drawnItems = new L.FeatureGroup()
  map.addLayer(drawnItems)
  const drawControl = new L.Control.Draw({
    draw: {
      polygon: {
        shapeOptions: {
          color: color,
          opacity: 0.8,
          weight: 2,
          fillColor: color,
          fillOpacity: 0.35
        }
      },
      polyline: false,
      rectangle: false,
      circle: false,
      marker: false,
      circlemarker: false
    },
    edit: {
      featureGroup: drawnItems,
      edit: edit,
      remove: false
    }
  })
  map.addControl(drawControl)
  map.on(L.Draw.Event.CREATED, e => {
    e.layer.options.type = 'drawnPolygon'
    drawnItems.addLayer(e.layer)
    creationCallback(e)
  })
}

export const enableDrawEditOnly = (map, polygons, onEdit = () => {}) => {
  const drawControl = new L.Control.Draw({
    draw: {
      polygon: false,
      polyline: false,
      rectangle: false,
      circle: false,
      marker: false,
      circlemarker: false
    },
    edit: {
      featureGroup: polygons,
      edit: true,
      remove: false
    }
  })
  map.addControl(drawControl)

  map.on(L.Draw.Event.EDITED, onEdit)
}

export const transformCoords = (coords) => {
  return coords.map(c => c.map(i => i.lat + ',' + i.lng)).flat().join(';')
}

export const isGeoInsidePolygon = (x, y, polyPoints) => {
  let inside = false;
  for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    const xi = polyPoints[i].lat, yi = polyPoints[i].lng;
    const xj = polyPoints[j].lat, yj = polyPoints[j].lng;

    const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}

export const buildTip = ({ name, address }) => {
  return `<div id="content">
        <div id="siteNotice">
        <h4 class="firstHeading">${name}</h4>
        <h5 class="firstHeading">${address}</h5></div></div>`
}

const getClassNameByStatus = marker => {
  if (marker.is_done) {
    return 'blue-number-icon'
  } else if (marker.failed_at) {
    return 'brown-number-icon'
  } else {
    return 'red-number-icon'
  }
}

export const locationsApiPoll = async (driverId, map, controlLayers) => {
  const icon = L.divIcon({ className: 'driver-icon blinking', iconSize: [30, 30], iconAnchor: [22, 15] })
  let markerCurrent
  let trackingFG
  let runInterval = null

  $(document).on('addedMarkerCurrent', (_e, { points }) => {
    trackingFG = L.featureGroup(points).addTo(map)
    controlLayers?.addOverlay(trackingFG, i18n.t('views.map.live_tracking'))
  })
  const run = async () => {
    const date = gon.date || moment().format("DD/MM/YYYY")
    const response = await fetchCurrentLocations(driverId.toString(), date)
    if (response === 'unauthorized') {
      if (runInterval) { clearInterval(runInterval) }
      return false
    } else if (!response) {
      return false
    }

    const { data: { locations } } = response
    if (locations.length > 0) {
      const locationTimestamps = locations.map(i => (i.date_time))
      const lastLocation = locations[locations.length - 1]
      const dateTime = lastLocation.date_time
      const latitude = lastLocation.latitude
      const longitude = lastLocation.longitude
      let popupText = null
      if (dateTime) {
        $('.last-location-updated-time').empty()
        $('.last-location-updated-time').text(i18n.t('location_last_updated',
          {
            time: dateTimeParser(i18n.locale, new Date(dateTime))
          })
        )
        popupText = i18n.t('location_last_updated', { time: dateTimeParser(i18n.locale, new Date(dateTime)) })
      }
      if (latitude) {
        const points = locations.map(i => (L.circleMarker([i.latitude, i.longitude], { radius: 4, color: '#000' })))
        if (!markerCurrent) {
          markerCurrent = L.marker([latitude, longitude], { icon: icon }).addTo(map)

          $(document).trigger('addedMarkerCurrent', { points: points })
        } else {
          locations.filter(i => (!locationTimestamps.includes(i.date_time)))
            .forEach(i => {L.circleMarker([i.latitude, i.longitude], { radius: 4, color: '#000' }).addTo(trackingFG)})
          markerCurrent.setLatLng([latitude, longitude])
        }

        if (popupText) markerCurrent.bindPopup(popupText, { offset: [-10, -5] })
      }
    }
  }

  await run()
  runInterval = setInterval(run, 4500)

  // stop polling in 20min ========================>
  const clearIntervalInterval = setInterval(() => {
    if (runInterval) {
      clearInterval(runInterval)
      clearInterval(clearIntervalInterval)
    }
  }, 1200000)
  // stop polling in 20min ========================>
}

export const constructMarkerIcon = (markerUrl, visitKind) => {
  if (visitKind === 'PICKUP' || visitKind === 'RETURN') {
    return markerUrl.replace('chld=', 'chld=•')
  }

  return markerUrl
}

const groupVisitsByCoordinates = (visits) => (
  visits.reduce((visits, v) => {
    (visits[`${v.lat}, ${v.lon}`] = visits[`${v.lat}, ${v.lon}`] || []).push(v)
    return visits
  }, {})
)

export const buildMarkers = (map, visits, buildUnitedMarker, buildSingleMarker) => {
  const filteredVisits = visits.filter(v => v.lat && v.lon)
  const markers = []
  Object.entries(groupVisitsByCoordinates(filteredVisits)).map((group) => {
    const visits = group[1]
    markers.push(visits.length > 1 ? buildUnitedMarker(map, visits) : buildSingleMarker(map,  visits[0]))
  })
  return markers
}

export const markerUrl = (color, text = '') => (
  `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${text}|${color}`
)

export const getAllMapMarkers = (map) => {
  const markers = []
  map.eachLayer((layer) => { if (layer instanceof L.Marker) markers.push(layer) })
  return markers
}

export const getMapMarkerByName = (map, name) => (
  getAllMapMarkers(map).find(marker => marker.options.name === name)
)

export const getDrawnPolygons = (map) => {
  const polygons = []
  map.eachLayer((layer) => { if (layer instanceof L.Polygon && layer.options.type === 'drawnPolygon') polygons.push(layer) })
  return polygons
}

export const fitBounds = (map) => {
  const markers = getAllMapMarkers(map)
  if (markers.length) map.fitBounds(L.featureGroup(markers).getBounds(), { padding: [10, 10] })
}

export const ambiguousMarkerAddition = () => (
  gon.advanced_marker_display ? '<div class="marker-addition"><i class="fa-solid fa-triangle-exclamation text-warning"></i></div>' : ''
)
