import { i18n } from "./i18n";
import { isRTL } from './locale'
import { dateOrCurrent, getDateFormat } from './date'
import moment from 'moment'
import { csrfToken, getCookie, getParameterByName, setPropertyByPath, updateQueryStringParameter } from './utils'
import { openGettModal } from "./external_deliveries/gett";
import { openWoltModal } from "./external_deliveries/wolt";
import { openYangoModal } from "./external_deliveries/yango";
import { showNotification } from "./notifications";

const trackFormChanges = (forms) => {
  forms.each((i, form) => {
    form = $(form)
    const originalFormData = form.serialize()
    form.find(':input').on('change input', () => {
      form.data("changed", form.serialize() !== originalFormData)
    })
  })
}

export const initTabs = (defaultTab = 'general', onTabChange = () => {}) => {
  let initialTab = getParameterByName("tab") ? getParameterByName("tab") : defaultTab
  $('.nav-tabs.nav-form-tabs > .nav-link, .nav-form-tabs > .navi-item > a.navi-link, .tab-form-content > .tab-pane').removeClass('active')
  $(`.nav-tabs.nav-form-tabs > .nav-link[href='#${initialTab}'], .nav-tabs.nav-form-tabs > .navi-item > .navi-link[href='#${initialTab}'], .tab-form-content > #${initialTab}`).addClass('active')
  onTabChange(initialTab)

  $('.nav-form-tabs > .nav-link, .nav-form-tabs > .navi-item > a.navi-link').click((e) => {
    e.preventDefault()
    const form = $('.tab-pane.active').find('form')
    const isFormChanged = form.data('changed')
    if (isFormChanged && !confirm(i18n.t('shared.unsaved_changes'))) {
      e.stopPropagation()
      return
    }

    form.trigger("reset").data('changed', false)
    let tab = $(`${e.currentTarget.getAttribute('href')}`)
    $('.nav-tabs.nav-form-tabs .nav-link, .nav-form-tabs > .navi-item > a.navi-link, .tab-form-content > .tab-pane').removeClass('active')
    $(e.currentTarget).addClass('active')
    tab.addClass('active')
    history.pushState({}, null, updateQueryStringParameter(window.location.href, 'tab', tab.attr('id')));
    onTabChange(tab.attr('id'))
  })

  trackFormChanges($('form'))
}

export const rememberTabs = (defaultTab = 'general') => {
  let initialTab = getParameterByName("tab") ? getParameterByName('tab') : defaultTab
  $('.nav-tabs.nav-remember-tabs > .nav-link, .nav-remember-tabs > .navi-item > a.navi-link, .tab-remember-content > .tab-pane').removeClass('active')
  $(`.nav-tabs.nav-remember-tabs > .nav-link[href='#${initialTab}'], .nav-tabs.nav-remember-tabs > .navi-item > .navi-link[href='#${initialTab}'], .tab-remember-content > #${initialTab}`).addClass('active')

  $('.nav-remember-tabs > .nav-link, .nav-remember-tabs > .navi-item > a.navi-link').click((e) => {
    let tab = $(`${e.currentTarget.getAttribute('href')}`)
    history.pushState({}, null, updateQueryStringParameter(window.location.href, 'tab', tab.attr('id')));
  })
}

export const renderSmallAjaxStatus = (statusId, taskId, roundtrip, allowInventory = false) => {
  const statusName = i18n.t(`models.task.status_enum.${statusId}`)
  const statusClass = statusId.replace('_', '-').toLowerCase()
  let roundtripOption = ''
  let inventoryOptions = ''
  if (roundtrip) {
    roundtripOption = `<li><span class="badge badge-status task-set-status roundtrip-delivered-task status-option"
        data-task-id="${taskId}" data-new-status="ROUNDTRIP_DELIVERED">
        ${i18n.t('models.task.status_enum.ROUNDTRIP_DELIVERED')}
        </span></li>`
  }
  if (allowInventory) {
    inventoryOptions = `
        <li><span class="badge badge-status task-set-status in-inventory-task status-option"
        data-task-id="${taskId}" data-new-status="IN_INVENTORY">
            ${i18n.t('models.task.status_enum.IN_INVENTORY')}
        </span></li>
        <li><span class="badge badge-status task-set-status out-inventory-task status-option"
        data-task-id="${taskId}" data-new-status="OUT_INVENTORY">
            ${i18n.t('models.task.status_enum.OUT_INVENTORY')}
        </span></li>
        <li><span class="badge badge-status task-set-status in-transfer-task status-option"
        data-task-id="${taskId}" data-new-status="IN_TRANSFER">
            ${i18n.t('models.task.status_enum.IN_TRANSFER')}
        </span></li>`
  }

  return `<div class="btn-group ajax-status-container" data-task-id="${taskId}">
            <span class="badge badge-status dropdown-toggle ajax-status-current ${statusClass}-task" type="button" data-toggle="dropdown" aria-expanded="false" style="min-width:85px;">
                <span class="ajax-status-current-text">${statusName}</span>
                <span><i class="fa-light fa-refresh fa-spin fa-fw margin-bottom ajax-status-spinner" style="display:none;"></i></span>
            </span>
            <ul class="dropdown-menu p-0" role="menu" style="width:85px !important; min-width:85px !important">
                <li><span class="badge badge-status task-set-status unassigned-task status-option" data-task-id="${taskId}" data-new-status="UNASSIGNED">${i18n.t('models.task.status_enum.UNASSIGNED')}</span></li>
                <li><span class="badge badge-status task-set-status assigned-task status-option" data-task-id="${taskId}" data-new-status="ASSIGNED">${i18n.t('models.task.status_enum.ASSIGNED')}</span></li>
                <li><span class="badge badge-status task-set-status active-task status-option" data-task-id="${taskId}" data-new-status="ACTIVE">${i18n.t('models.task.status_enum.ACTIVE')}</span></li>
                ${roundtripOption}
                <li><span class="badge badge-status task-set-status completed-task status-option" data-task-id="${taskId}" data-new-status="COMPLETED">${i18n.t('models.task.status_enum.COMPLETED')}</span></li>
                <li><span class="badge badge-status task-set-status canceled-task status-option" data-task-id="${taskId}" data-new-status="CANCELED">${i18n.t('models.task.status_enum.CANCELED')}</span></li>
                ${inventoryOptions}
                <li><span class="badge badge-status task-set-status failed-task status-option" data-task-id="${taskId}" data-new-status="FAILED">${i18n.t('models.task.status_enum.FAILED')}</span></li>
                <li><span class="badge badge-status task-set-status final-failed-task status-option ${ gon.allow_final_failed ? '' : 'd-none' }" data-task-id="${taskId}" data-new-status="FINAL_FAILED">${i18n.t('models.task.status_enum.FINAL_FAILED')}</span></li>
            </ul>
         </div>`
}

export const renderPickStatusAjax = (taskId, pickStatus, packagesQuantity = 1) => {
  const statuses = ["NEW", "PENDING", "PICKED", "PARTIALLY_PICKED"]

  return `
    <div class="task-pick-status-dropdown-${taskId} btn-group">
        <span class="pick-status badge badge-status dropdown-toggle pick-status-${pickStatus.toLowerCase()}" type="button" data-toggle="dropdown">
            <span class="pick-status-text">
                ${i18n.t('models.task.pick_status_enum.' + pickStatus)}
             </span>
            <span><i class="pick-status-spinner fa-light fa-refresh fa-spin fa-fw margin-bottom" style="display:none;"></i></span>
        </span>
        <ul class="dropdown-menu px-0" role="menu" style="min-width: 85px;">
            ${statuses.map(s => `
                <li class="cursor-pointer">
                    <span class="badge badge-status task-set-pick-status pick-status-${s.toLowerCase()}" data-task-id="${taskId}" data-new-status="${s}" data-packages-quantity="${packagesQuantity}">
                        ${i18n.t('models.task.pick_status_enum.' + s)}
                    </span>
                </li>
            `).join('')}
        </ul>
    </div>
  `
}

export const getPathFromUrl = url => url.split('?')[0]

export const toggleOther = (target, content) => {
  content.toggleClass('collapse')
  if (!content.hasClass('collapse')) {
    target.val('').prop('disabled', true).trigger('change')
  } else {
    target.prop('disabled', false)
  }
}

export const initRoutesDropdown = (callback = () => { location.reload() }) => {
  const $container = $('.routes-dropdown')
  if ($container.length) {
    const currentRouteId = $container.data('route-id')
    const visitId = $container.data('visit-id')
    const routesArr = $container.data('routes')
    const $list = $container.find('ul').first()

    const removeLink = () => (
      `<li class="dropdown-divider"></li>
       <li>
         <a class="btn btn-danger dropdown-item align-items-center" href="#" data-route-id="remove">
           <i class="fa-light fa-remove"></i> ${i18n.t('views.routes.unassign_route')}
         </a>
       </li>`
    )

    const list = (routes, inputVal = '') => {
      const start = `<li><div class="search"><input class="form-control" type="text" value="${inputVal}"></div></li>`
      let list = routes.reduce((acc, r) => {
        return acc + `<li>
              <a class="dropdown-item d-flex align-items-center" href="#" data-route-id="${r.id}">
                <span>${r.name}</span>
              </a>
            </li>`
      }, start)
      return currentRouteId ? list.concat(removeLink()) : list
    }

    const rebuild = (e) => {
      $list.empty()
      $list.append(list(
        routesArr.filter(r => r.name.search(new RegExp(e.target.value, 'i')) !== -1),
        e.target.value
      ))
      $container.find('.dropdown-item').click(e => {
        e.preventDefault()
        const routeId = e.currentTarget.dataset.routeId
        updateVisitRoute(visitId, routeId, callback)
      })
    }

    $container.on('show.bs.dropdown', () => {
      $list.empty()
      $list.append(list(routesArr)).ready(() => {
        $container.find('.dropdown-item').click(e => {
          e.preventDefault()
          const routeId = e.currentTarget.dataset.routeId
          updateVisitRoute(visitId, routeId, callback)
        })
        const $input = $container.find('input').first()
        $input.focus()
        $input.on('input', rebuild)
      })
    })
  }
}

export const initVehiclesDropdown = () => {
  const $container = $('.vehicles-dropdown')
  if ($container.length) {
    const vehicleId = $container.data('vehicle-id')
    const vehiclesArr = $container.data('vehicles')
    const $list = $container.find('ul').first()
    const form = $('.route-vehicle-form')

    const removeLink = () => (
      `<li class="dropdown-divider"></li>
       <li>
         <a class="btn btn-danger dropdown-item align-items-center" href="#" data-vehicle="remove">
           <i class="fa-light fa-remove"></i> ${i18n.t('views.routes.unassign_vehicle')}
         </a>
       </li>`
    )

    const list = (vehicles, inputVal = '') => {
      const start = `<li><div class="search"><input class="form-control" type="text" value="${inputVal}"></div></li>`
      let list = vehicles.reduce((acc, v) => {
        return acc + `<li>
              <a class="dropdown-item d-flex align-items-center" href="#" data-vehicle-id="${v.id}">
                <span>${v.name}</span>
              </a>
            </li>`
      }, start)
      return vehicleId ? list.concat(removeLink()) : list
    }

    const rebuild = (e) => {
      $list.empty()
      $list.append(list(
        vehiclesArr.filter(r => r.name.search(new RegExp(e.target.value, 'i')) !== -1),
        e.target.value
      ))
      const $input = $container.find('input').first()
      const val = $input.val()
      $input.focus().val('').val(val)
      $input.on('input', rebuild)
      $container.find('.dropdown-item').click(e => {
        e.preventDefault()
        const vehicleId = e.currentTarget.dataset.vehicleId
        form.find('#route_vehicle_id').val(vehicleId)
        form.submit()
      })
    }

    $container.on('show.bs.dropdown', () => {
      $list.empty()
      $list.append(list(vehiclesArr)).ready(() => {
        $container.find('.dropdown-item').click(e => {
          e.preventDefault()
          const vehicleId = e.currentTarget.dataset.vehicleId
          form.find('#route_vehicle_id').val(vehicleId)
          form.submit()
        })
        const $input = $container.find('input').first()
        $input.focus()
        $input.on('input', rebuild)
      })
    })
  }
}

export const initDriversDropdown = (isAsync = false, callback = () => { window.location.reload() }) => {
  const $container = $('.drivers-dropdown')
  if ($container.length) {
    const { id, driverId } = $container.data('task')
    const driversArr = $container.data('drivers')
    const $list = $container.find('ul').first()
    const driverLink = (taskId, driverId) => (`data-method="post" href="/tasks/assign_driver?task_id=${taskId}&driver_id=${driverId}"`)
    const asyncDriverUpdate = (e) => {
      const { taskId, driverId, transfer } = e.currentTarget.dataset

      if (gon.confirm_task_transfers && transfer) {
        initTransferConfirmModal(driverId, { task_id: taskId }, (transferDetails) => {
          updateDriver(taskId, driverId, transferDetails, callback)
        })
      } else {
        if (!isAsync && !transfer) return

        updateDriver(taskId, driverId, {}, callback)
      }
    }

    const removeLink = () => (
      `<li class="mt-3">
         <a class="btn btn-danger rounded-0 dropdown-item align-items-center cursor-pointer update-task-driver" data-task-id="${id}" data-driver-id=""
            ${isAsync ? '' : driverLink(id, '')}>
           <i class="fa-light fa-remove"></i> ${i18n.t('drivers.unassign_driver')}
         </a>
       </li>`
    )

    const gettLink = () => {
      return `
        <li class="gettDriver cursor-pointer" data-task-id="${id}">
            <span class="dropdown-item d-flex align-items-center">
                <img class="rounded h-30px mr-2" src="/gett-icon.png" alt="">
                GETT
            </span>
        </li>
      `
    }

    const woltLink = () => {
      return `
        <li class="woltDriver cursor-pointer" data-task-id="${id}">
            <span class="dropdown-item d-flex align-items-center">
                <img class="rounded h-30px mr-2" src="/wolt.png" alt="">
                Wolt
            </span>
        </li>
      `
    }

    const yangoLink = () => {
      return `
        <li class="yangoDriver cursor-pointer" data-task-id="${id}">
            <span class="dropdown-item d-flex align-items-center">
                <img class="rounded h-25px mr-2" src="/yango.jpeg" alt="">
                Yango
            </span>
        </li>
      `
    }

    const list = (drivers, inputVal = '') => {
      const regularDrivers = drivers.filter((d) => !d.partner_bridge_id)
      const partnerDrivers = drivers.filter((d) => d.partner_bridge_id)

      const start = `<li><div class="search"><input class="form-control" type="text" value="${inputVal}"></div></li>`
      let list = regularDrivers.reduce((acc, d) => {
        return acc + `<li>
              <a class="dropdown-item d-flex align-items-center cursor-pointer update-task-driver" data-task-id="${id}" data-driver-id="${d.id}"
                 ${isAsync ? '' : driverLink(id, d.id)}>
                <span>${d.name}</span>
              </a>
            </li>`
      }, start)

      if (partnerDrivers.length) {
        list = list.concat(`<li class='text-center px-5 py-2 border-bottom font-weight-bolder'>${i18n.t('drivers.partner_drivers')}</li>`)
      }

      list = partnerDrivers.reduce((acc, d) => {
        return acc + `<li>
              <div class="dropdown-item d-flex align-items-center cursor-pointer update-task-driver" data-task-id="${id}" data-driver-id="${d.id}" data-transfer="true">
                ${ d.partner_bridge_image ? (`<img class="mr-2 h-25px" src="${d.partner_bridge_image}" alt="" />`) : ''}
                <span>${d.name}</span>
              </div>
            </li>`
      }, list)

      if (gon.allow_gett) { list = list.concat(gettLink()) }
      if (gon.allow_wolt) { list = list.concat(woltLink()) }
      if (gon.allow_yango) { list = list.concat(yangoLink()) }
      return (driverId || isAsync) ? list.concat(removeLink()) : list
    }

    const rebuild = (e) => {
      const filtered = driversArr.filter(d => d.name.search(new RegExp(e.target.value, 'i')) !== -1)
      $list.empty()
      $list.append(list(filtered, e.target.value))
      const $input = $container.find('input').first()
      const val = $input.val()
      $input.focus().val('').val(val)
      $input.on('input', rebuild)

      $('.gettDriver').on('click', (e) => { openGettModal(e.currentTarget.dataset.taskId, null) });
      $('.woltDriver').on('click', (e) => { openWoltModal(e.currentTarget.dataset.taskId, null) });
      $('.yangoDriver').on('click', (e) => { openYangoModal(e.currentTarget.dataset.taskId, null) });
      $('.update-task-driver').off('click').on('click', asyncDriverUpdate)
    }

    $container.on('show.bs.dropdown', () => {
      $list.empty()
      $list.append(list(driversArr)).ready(() => {
        const $input = $container.find('input').first()
        $input.focus()
        $input.on('input', rebuild)
      })

      $('.gettDriver').off('click').on('click', (e) => { openGettModal(e.currentTarget.dataset.taskId, null) });
      $('.woltDriver').off('click').on('click', (e) => { openWoltModal(e.currentTarget.dataset.taskId, null) });
      $('.yangoDriver').off('click').on('click', (e) => { openYangoModal(e.currentTarget.dataset.taskId, null) });
      $('.update-task-driver').off('click').on('click', asyncDriverUpdate)
    })
  }
}

const fetchWithLoading = async (url, body, method, callback = () => {}) => {
  const loader = $('.data-preloader')
  loader.removeClass('d-none')
  const res = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify(body)
  })

  if (res.ok) {
    res.json().then(data => { callback(data) })
  } else {
    showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
  }
  loader.addClass('d-none')
}

const updateDriver = async (taskId, driverId, transferDetails, callback = () => {}) => {
  fetchWithLoading('/tasks/assign_driver.json', { task_id: taskId, driver_id: driverId, transfer_details: transferDetails }, 'POST', callback)
}

const updateVisitRoute = async (visitId, routeId, callback = () => {}) => {
  fetchWithLoading(`/visits/${visitId}.json`, { visit: { route_id: routeId } }, 'PATCH', callback)
}

export const tooltipsTaskLink = (id, isPhotoAttached = false, signatureUrl = null, printedAt,
                                 comments= [], visitId = null, mapVisitId = null, isVipClient = false) => {
  const commentsHtml = comments.map(comment => (
    `<div class='row'>
      <div class='col-3'>${comment.created_at_str}</div>
      <div class='col-3'>${comment.username}</div>
      <div class='col-6'>${comment.text}</div>
    </div>`
  )).join('')

  return (
  `<div class="d-flex flex-nowrap align-items-center">
      <a class="task-panel-view-open task-row-${mapVisitId}" data-task-id="${id}" target="_blank" href="/tasks/${id}">
      <div class="d-flex align-items-center mt-1">
          <span>${id}</span>
      </div>
      </a>
    ${ isPhotoAttached ? (
    `<span class="d-flex flex-nowrap align-items-center">
      <a href="#" class="task-photos" data-task-id="${id}" title="${i18n.t('models.task.photo_url')}">
        <i class="fa-light fa-file-image ml-1 d-flex" style="font-size: 20px;"></i>
      </a>
    </span>`
    ) : ''}
    ${ isVipClient ? (
    `<span class="d-flex flex-nowrap align-items-center">
      <a href="#" class="vip-client" title="${i18n.t('views.clients.vip_client')}">
        <i class="fa fa-star vip-clients ml-1 d-flex" style="font-size: 20px;"></i>
      </a>
    </span>`
    ) : ''}
    ${ signatureUrl ? (
    `<a class="d-flex flex-nowrap align-items-center" href="${signatureUrl}" target="_blank">
        <span title="${i18n.t('models.task.signature_url')}">
            <i class="fa-light fa-signature ml-1 d-flex" style="font-size: 20px;"></i>
        </span>
     </a>`
    ) : ''}
    ${ printedAt ? (
    `<span data-toggle="tooltip" data-html="true" title="${printedAt}">
        <i class="fa-light fa-barcode ml-1 d-flex" style="font-size: 20px;"></i>
     </span>`
    ) : ''}
    ${ printedAt ? (
      `<span data-toggle="tooltip" data-html="true" title="${printedAt}">
        <i class="fa-light fa-print ml-1 d-flex" style="font-size: 20px;"></i>
      </span>`
    ) : ''}
    ${ comments.length > 0 ? (
    `<span class="ml-1" data-toggle="tooltip" data-html="true" title="<div class='w-375px text-left'><div class='row font-weight-bolder'><div class='col-3'>${i18n.t('date_str')}</div><div class='col-3'>${i18n.t('models.user.username')}</div><div class='col-6'>${i18n.t('shared.text')}</div></div>${commentsHtml}</div>">
        <i class="far fa-comment-alt m-0 d-flex comments-count"><span>${comments.length}</span></i>
     </span>`
  ) : ''}
    ${ visitId ? (
      `<span data-toggle="tooltip" data-html="true" title="${i18n.t('drivers.daily_route_plan.show_on_map')}">
        <a href="#" class="pe-auto show-on-map" data-id="${visitId}">
          <i class="fa-light fa-map ml-1 d-flex" style="font-size: 20px;"></i>
        </a>
      </span>`
    ) : ''}
  </div>`)
}

export const initSidePanel = (panel, resizeSelector, onOpen = () => {}, onDragEnd = () => {}) => {
  let dragStartWidth = getCookie('side_map_width')
  panel.resizable({
    handleSelector: resizeSelector,
    resizeHeight: false,
    resizeWidthFrom: isRTL() ? 'right' : 'left',
    onDragStart: (e, el) => { dragStartWidth = el.width() },
    onDrag: () => {
      if (!panel.data('dragging')) panel.data('dragging', true)
    },
    onDragEnd: (e, el) => {
      if (!panel.data('dragging')) return
      window.dispatchEvent(new Event('resize'))
      setTimeout(() => panel.data('dragging', false), 1) // to prevent click
      document.cookie = `side_map_width=${el.width()}`
      if (dragStartWidth === 0) { onOpen() }
      onDragEnd()
    }
  })

  $(resizeSelector).click(() => {
    if (panel.data('dragging')) return

    const dragStartWidthCopy = dragStartWidth
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
      if (dragStartWidthCopy === 0) { onOpen() }
    }, 500)

    const newWidth = panel.width() > 0 ? 0 : 400
    panel.width(newWidth)
    dragStartWidth = newWidth
    document.cookie = `side_map_width=${newWidth}`
    onDragEnd()
  })
}

export const initPanelMapMoving = (breakpoint, callback) => {
  $(window).on('resize', () => {
    const width = $(document).width()

    if (width < breakpoint) {
      const mapContent = $('#desktop-map-container').children()
      if (mapContent.length) {
        mapContent.detach().appendTo('#mobile-map-container')
        setTimeout(() => { callback() }, 250)
      }
    } else {
      const mapContent = $('#mobile-map-container').children()
      if (mapContent.length) {
        mapContent.detach().appendTo('#desktop-map-container')
        setTimeout(() => { callback() }, 250)
      }
    }
  })
  setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 10)
}

export const initDateRangePicker = (element) => {
  if (!element.length) return

  const EARLIEST_DATE = moment("2020-01-01")
  const LATEST_DATE = moment("2100-01-01")

  const label = element.find('span')

  // workaround to make daterangepicker UI more clear for users
  const url = new URL(window.location.href)
  const fromParam = url.searchParams.get('from') || gon.from
  const toParam = url.searchParams.get('to') || gon.to
  if (dateOrCurrent(moment().utcOffset(gon.time_offset)) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset)) === toParam) {
    label.html(i18n.t('today'))
  } else if (dateOrCurrent(moment().utcOffset(gon.time_offset).subtract(1, "days")) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset).subtract(1, "days")) === toParam) {
    label.html(i18n.t('yesterday'))
  } else if (dateOrCurrent(moment().utcOffset(gon.time_offset).add(1, "days")) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset).add(1, "days")) === toParam) {
    label.html(i18n.t('tomorrow'))
  } else if (dateOrCurrent(moment().utcOffset(gon.time_offset).startOf("month")) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset).endOf("month")) === toParam) {
    label.html(i18n.t('this_month'))
  } else if (dateOrCurrent(moment().utcOffset(gon.time_offset).subtract(1, "month").startOf("month")) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset).subtract(1, "month").endOf("month")) === toParam) {
    label.html(i18n.t('last_month'))
  } else if (dateOrCurrent(moment().utcOffset(gon.time_offset).add(1, "month").startOf("month")) === fromParam && dateOrCurrent(moment().utcOffset(gon.time_offset).add(1, "month").endOf("month")) === toParam) {
    label.html(i18n.t('next_month'))
  } else if (dateOrCurrent(EARLIEST_DATE) === fromParam && dateOrCurrent(LATEST_DATE) === toParam) {
    label.html(i18n.t('all_open'))
  } else if (dateOrCurrent(EARLIEST_DATE) === fromParam && dateOrCurrent(moment().subtract(1, "days").endOf("day")) === toParam) {
    label.html(i18n.t('old_open'))
  } else if (null === fromParam && null === toParam) {
    label.html(i18n.t('today'))
  } else {
    label.html(`${fromParam} - ${toParam}`)
  }

  element.daterangepicker({
    startDate: dateOrCurrent(gon.from),
    endDate: dateOrCurrent(gon.to),
    ranges: {
      [i18n.t('today')]: [moment().utcOffset(gon.time_offset), moment().utcOffset(gon.time_offset)],
      [i18n.t('yesterday')]: [moment().utcOffset(gon.time_offset).subtract(1, "days"), moment().utcOffset(gon.time_offset).subtract(1, "days")],
      [i18n.t('tomorrow')]: [moment().utcOffset(gon.time_offset).add(1, "days"), moment().utcOffset(gon.time_offset).add(1, "days")],
      [i18n.t('this_month')]: [moment().utcOffset(gon.time_offset).startOf("month"), moment().utcOffset(gon.time_offset).endOf("month")],
      [i18n.t('last_month')]: [moment().utcOffset(gon.time_offset).subtract(1, "month").startOf("month"), moment().utcOffset(gon.time_offset).subtract(1, "month").endOf("month")],
      [i18n.t('next_month')]: [moment().utcOffset(gon.time_offset).add(1, "month").startOf("month"), moment().utcOffset(gon.time_offset).add(1, "month").endOf("month")],
      [i18n.t('all_open')]: [EARLIEST_DATE, LATEST_DATE],
      [i18n.t('old_open')]: [EARLIEST_DATE, moment().utcOffset(gon.time_offset).subtract(1, "days").endOf("day")]
    },
    locale: {
      format: getDateFormat({uppercase: true}),
      applyLabel: i18n.t('apply_custom_dates'),
      cancelLabel: i18n.t('cancel'),
      customRangeLabel: i18n.t('custom_range'),
      daysOfWeek: i18n.t('date.abbr_day_names'),
      monthNames: "i18n.t('date.month_names').slice(1)",
      direction: isRTL() ? 'rtl' : 'ltr',
    }
  }, (start, end) => {
    let url = window.location.href

    url = updateQueryStringParameter(url, 'date', 'range')
    url = updateQueryStringParameter(url, 'from', dateOrCurrent(start))
    url = updateQueryStringParameter(url, 'to', dateOrCurrent(end))

    window.location.replace(url)
  })
}

export const partPrint = (id) => {
  const domClone = document.getElementById(id).cloneNode(true)
  let printSection = document.getElementById("printSection")

  if (!printSection) {
    printSection = document.createElement("div")
    printSection.id = "printSection"
    document.body.appendChild(printSection)
  }

  printSection.appendChild(domClone)
  $('body').addClass('part-print')
  window.print()
  $('body').removeClass('part-print')
  printSection.innerHTML = ""
}

export const initTransferConfirmModal = async (driverId, body, onAccept = () => {}, onCancel = () => {}) => {
  const modalContainer = $('#modal-container')
  const loader = $('.data-preloader')
  loader.removeClass('d-none')

  await fetch(`/drivers/${driverId}/transfer_confirm_modal`, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }).then(res => res.text()).then(html => {
    modalContainer.html(html)
    const modal = modalContainer.children()

    modal.on('hide.bs.modal.transfer', onCancel)
    $('#transfer-accept').on('click', (e) => {
      $(e.currentTarget).prop('disabled', true)
      const transferDetails = {}
      $('#transfer-details-form').serializeArray().forEach(field => { setPropertyByPath(transferDetails, field.name, field.value) });
      delete transferDetails['authenticity_token']

      onAccept(transferDetails)
      modal.off('hide.bs.modal.transfer').modal('hide')
    })

    loader.addClass('d-none')
    modal.modal('show')
  })
}

export const flyOnMapToCoordinates = (targetObject, map) => {
  var zoomLevel = map.getZoom();
  const canvas = $(".offcanvas-custom");
  const marker = findMarkerOnMap(
    map, parseFloat(targetObject.lat || targetObject.latitude), parseFloat(targetObject.lon || targetObject.longitude)
  )

  if (marker && !marker.isPopupOpen()) {
    const latLon = marker.getLatLng()
    setTimeout(function () {map.invalidateSize(true)}, 50)
    if (canvas.width() < 400) { canvas.width(400) }
    marker.openPopup();
    zoomLevel = zoomLevel == 9 ? 17 : zoomLevel;
    map.panTo(latLon, zoomLevel).fitBounds(L.featureGroup([marker]).getBounds(), { padding: [10, 10], maxZoom: zoomLevel })
  }
}

const findMarkerOnMap = (map, latitude, longitude) => {
  let targetMarker = null;
  map.eachLayer(function (layer) {
    if (layer instanceof L.Marker) {
      const marker = layer;
      const markerLatLng = marker.getLatLng();
      if (markerLatLng.lat === latitude && markerLatLng.lng === longitude) {
        targetMarker = marker;
      }
    }
  });
  return targetMarker;
}

export const initHoverCopy = () => {
  $('.hover-copy').click(e => {
    e.stopPropagation()
    const temp = $("<input>");
    $("body").append(temp);
    temp.val((e.currentTarget.dataset.copy || e.currentTarget.textContent).trim()).select();
    document.execCommand("copy");
    temp.remove();

    const $el = $(e.currentTarget)
    $el.addClass('hover-copy-success').tooltip({ title: i18n.t('shared.copied'), placement: 'bottom', trigger: 'manual' }).tooltip('show')
    setTimeout(() => $el.removeClass('hover-copy-success').tooltip('hide'), 1500)
  })
}

export const initPasswordToggle = () => {
  $('.password-eye-icon').on('click', (e) => {
    const icon = $(e.currentTarget)
    const input = icon.parent('.password-eye-container').find('.password-eye-input')
    const isOpen = input.attr('type') === 'text'
    input.attr('type', isOpen ? 'password' : 'text')
    icon.toggleClass('password-open', !isOpen)
  })
}

export const initTaskCancelButton = ($el, onSuccess = () => { window.location.reload() }) => {
  $el.off('click').on('click', async (e) => {
    if (confirm(i18n.t('shared.are_you_sure'))) {
      const loader = $('.data-preloader')
      loader.removeClass('d-none')
      const taskId = e.currentTarget.dataset.taskId

      await fetch(`/tasks/${taskId}/set_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken()
        },
        body: JSON.stringify({ new_task_status: 'CANCELED' })
      }).then(res => {
        if (res.ok) res.json().then(data => { onSuccess(data) })
        loader.addClass('d-none')
      })
    }
  })
}

export const backgroundByPercents = (percents) => {
  if (!percents) return ''

  if (percents < 80) {
    return 'bg-success'
  } else if (percents < 100) {
    return 'bg-warning'
  } else {
    return 'bg-danger'
  }
}

export const initTaskPhotoModal = () => {
  $('.task-photos').off('click').on('click', (e) => {
    e.preventDefault();

    $('.data-preloader').removeClass('d-none');
    const taskId = e.currentTarget.dataset.taskId;
    fetch(`/tasks/${taskId}/photos`)
      .then(res => res.text())
      .then(html => {
        $('.data-preloader').addClass('d-none');
        $('#task-photos-modal-body').html(html);
        $('#task-photos-modal').modal('show');
      });
  })
}
